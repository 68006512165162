import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import styled from "styled-components"

import Image from "components/landing/image"
import Textarea from "components/landing/textarea"
import { isTestMode } from "helpers/application"
import { getUrlProps } from "helpers/landing"

import LegacyBanner from "./legacy-banner"

const Container = styled(Box).attrs({
  containWide: true,
})`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`

const PositionBlock = styled(Box).attrs((props) => ({
  bottom: { _: 0, tablet: "auto" },
  px: { _: "10px", tablet: 2 },
  py: { _: "20px", tablet: 0 },
  top: { tablet: "50%" },
  width: "100%",
  ...props,
}))`
  position: absolute;
  z-index: 1;
  ${(props) => props.theme.mediaQueries.tablet`
    transform: translateY(-50%);
  `}
`

const HomepageBanner = ({ firstElement, salebanner }) => {
  const blockRef = useRef()

  if (!salebanner) return <LegacyBanner />

  const handleClick = () => {
    if (!isTestMode) {
      dataLayer.push({
        event: "clicked-homepage-banner",
      })
    }
  }
  const urlProps = getUrlProps(
    salebanner.image?.url,
    null,
    salebanner.id,
    handleClick
  )
  const textarea = salebanner.textarea

  return (
    <Box
      mx="auto"
      position="relative"
      textAlign="center"
      {...salebanner.container_style}
      {...salebanner.style?.container_style}
    >
      {salebanner.image && (
        <Box aria-label={salebanner.image.alt} {...urlProps}>
          <Image
            firstElement={firstElement && !textarea}
            image={salebanner.image}
            lazyload={false}
            linkClickData="homepage-banner"
          />
        </Box>
      )}
      {textarea && (
        <Container
          onClick={() => {
            if (blockRef.current) {
              const elem =
                blockRef.current.querySelector("a") ||
                blockRef.current.querySelector("button")

              elem?.click()
            }
          }}
          pointer={salebanner.image?.url}
          ref={blockRef}
        >
          <PositionBlock {...textarea.style}>
            <Textarea
              element={textarea}
              firstElement={firstElement}
              lazyload={false}
              linkClickData="home-banner"
              linkProps={{ onClick: handleClick }}
              style={textarea.textBlockStyle}
            />
          </PositionBlock>
        </Container>
      )}
    </Box>
  )
}

HomepageBanner.propTypes = {
  firstElement: PropTypes.bool,
  salebanner: PropTypes.object,
}

export default HomepageBanner
